.my-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background-color: rgba(255, 255, 255, 0.7) !important;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.my-navbar .navbar-img {
    display: flex;
}

.my-navbar .navbar-img img {
    height: 150px;
    margin: 0 auto 10px auto;
    transition: width 0.5s ease, height 0.5s ease;
}


.my-navbar .navbar-main {
    margin: auto auto auto auto;
    max-width: 80vw;

}

.my-navbar a {
    color: #333;
    text-decoration: none;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 20px;
    font-weight: 500;
    transition: font-size 0.5s ease, height 0.5s ease;

}

.my-navbar a:hover {
    text-decoration: underline;
    text-transform: capitalize;
    font-size: 150%;
}